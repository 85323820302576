/* autoprefixer grid: autoplace */
body {
  margin: 0;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#start {
  height: 100vh;
}

@font-face {
  font-family: "Muli";
  src: url(/static/media/Muli-Regular.4d7470e7.eot);
  src: url(/static/media/Muli-Regular.4d7470e7.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Muli-Regular.17644b8a.woff2) format("woff2"),
    url(/static/media/Muli-Regular.d2456f35.woff) format("woff"),
    url(/static/media/Muli-Regular.d80f1393.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url(/static/media/Muli-SemiBold.2bb9f67d.eot);
  src: url(/static/media/Muli-SemiBold.2bb9f67d.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Muli-SemiBold.fa988e40.woff2) format("woff2"),
    url(/static/media/Muli-SemiBold.a441a45a.woff) format("woff"),
    url(/static/media/Muli-SemiBold.d3db23a8.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Muli bold */

@font-face {
  font-family: "Muli";
  src: url(/static/media/Muli-Bold.262c2f3d.eot);
  src: url(/static/media/Muli-Bold.262c2f3d.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Muli-Bold.ac001690.woff2) format("woff2"),
    url(/static/media/Muli-Bold.75fbec90.woff) format("woff"),
    url(/static/media/Muli-Bold.e004c490.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

